import React from 'react'
import styled from 'styled-components'
import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Logo from '../components/shared/Logo'
import Button from '../components/Button/Button'
import { color, fontSize } from '../components/shared/styles'
import SmallProduct from '../components/SmallProduct'
import Container from '../components/shared/Container'

const StripSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 200px 0 70px 0;
  background: ${color.black};
  color: ${color.white};
  align-items: center;
  justify-content: center;

  h2 {
    font-size: ${fontSize.md};
    text-transform: uppercase;
    text-align: center;
  }

  p {
    max-width: 600px;
    font-size: ${fontSize.sm};
    text-align: center;
    padding: 30px;
  }
`

const ProductsSection = styled.section`
  height: auto;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${props => props.bgImg || ''});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 106px;

  > div:nth-child(2) {
    text-align: center;
  }

  @media screen and (max-width: 769px) {
    padding: 90px 50px;
  }
`

const ProductsShelf = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  align-items: flex-start;
  margin-bottom: 40px;

  @media screen and (max-width: 1145px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const Exito = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProduct {
        nodes {
          id
          name
          performance
          displayHome
          subTitle
          description
          route {
            current
          }
          bgImage {
            asset {
              url
            }
          }
          images {
            asset {
              url
            }
          }
          cutsheet {
            asset {
              url
            }
          }
          footNotes
        }
      }
    }
  `)


  const products = data?.allSanityProduct?.nodes?.map(
    ({
      id,
      name,
      route,
      displayHome,
      performance,
      subTitle,
      description,
      bgImage,
      images,
      cutsheet,
      footNotes
    }) => ({
      id,
      name,
      route: route.current,
      displayHome,
      performance,
      subTitle,
      description,
      bgImage: bgImage.asset.url,
      images: images.map(({ asset }) => ({ url: asset.url })),
      cutsheet: cutsheet.asset.url,
      footNotes
    })
  )

  const productsSectionBg = data?.allSanitySiteSettings?.nodes[0]?.homeSecondSectionBg?.asset?.url
  const meta = [
    {
      name: 'robots',
      content: 'noindex, nofollow'
    },
    {
      name: 'googlebot',
      content: 'noindex'
    }
  ]

  return (
  <Layout>
    <Seo title="Gracias" meta={meta} />
    <StripSection>
      <h2>Consulta enviada exitosamente</h2>
      <p>Gracias por comunicarte con nosotros. Nos pondremos en contacto a la bravedad para responder tus consultas.</p>
      <Logo height={56} isBgCentered />
    </StripSection>
    <ProductsSection bgImg={productsSectionBg}>
      <Container>
        <ProductsShelf>
          {products
            .filter(product => product.displayHome)
            .map(product => <SmallProduct key={product.id} product={product} />)}
        </ProductsShelf>
        <Button to="/productos" height="50px" variant="black" align="center">PRODUCTOS</Button>
      </Container>
    </ProductsSection>
  </Layout>
)}

export default Exito
